module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Bilda","short_name":"Bilda","description":"Bilda","theme_color":"#351539","background_color":"#ffe0b4","icon":"static/images/meta/icon-bg.png","include_favicon":false,"start_url":"/","display":"minimal-ui","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":"2ab1a5c3b777a2ac087928d853abed1e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
