/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import initSmartOutline from "@oddcamp/js-utils/src/smart-outline"
import smoothscroll from "smoothscroll-polyfill"
import svg4everybody from "svg4everybody"

export function onClientEntry() {
  smoothscroll.polyfill()

  svg4everybody()

  initSmartOutline([
    `input:focus`,
    `button:focus`,
    `textarea:focus`,
    `select:focus`,
  ])
}

export function onRouteUpdate({ location, prevLocation }) {
  if (location && prevLocation) {
    const event = document.createEvent(`Event`)
    event.initEvent(`onRouteChange`, true, true)
    window.dispatchEvent(event)

    if (window.trackingUtil && window.trackingUtil.trackingAccepted()) {
      window.trackingUtil.runGAcommand([`send`, `pageview`])
    }
  }
}
